import { Send28Filled } from "@fluentui/react-icons";
import { Tooltip } from 'react-tooltip'
import styles from "./SendChatButton.module.css";

interface Props {
    className?: string;
    onClick: () => void;
    disabled?: boolean;
}

export const SendChatButton = ({ className, disabled, onClick }: Props) => {
    return (
        <button className={`${styles.container} ${className ?? ""} ${disabled ? styles.disabled : styles.enabled}`} onClick={onClick}>
            <Send28Filled aria-label="Ask question button" data-tooltip-id="send-tooltip" data-tooltip-content="Vraag verzenden" className={styles.sendQuestionIcon} />
            <Tooltip id="send-tooltip" />
        </button>
    );
};
