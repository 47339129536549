import { Example } from "./Example";
import styles from "./Example.module.css";


interface Props {
    onExampleClicked: (value: string) => void;
    examples: string[]
}

export const ExampleList = ({ onExampleClicked, examples }: Props) => {
    return (
        <ul className={styles.examplesNavList}>
            {examples.map((x) => (
                <li key={self.crypto.randomUUID()}>
                    <Example text={x} onClick={onExampleClicked} />
                </li>
            ))}
        </ul>
    );
};
