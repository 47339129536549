import { parsedAnswer } from "./AnswerTypes";
import { renderToStaticMarkup } from "react-dom/server";
import styles from "./Answer.module.css";

export function parseAnswer(answer: string, isStreaming: boolean): parsedAnswer {
    const citations: string[] = [];
    
    // trim any whitespace from the end of the answer after removing follow-up questions
    let trimmedAnswer = answer.trim();

    // omit a citation that is still being typed during streaming
    if (isStreaming) {
        let lastIndex = trimmedAnswer.length;
        for (let i = trimmedAnswer.length - 1; i >= 0; i--) {
            if (trimmedAnswer[i] === "]") {
                break;
            } else if (trimmedAnswer[i] === "[") {
                lastIndex = i;
                break;
            }
        }
        const truncatedAnswer = trimmedAnswer.substring(0, lastIndex);
        trimmedAnswer = truncatedAnswer;
    }

    const parts = trimmedAnswer.split(/\[([^\]]+.pdf[^\]]+)\]/g);

    const fragments: string[] = parts.map((part, index) => {
        if (index % 2 === 0) {
            return part;
        } else {
            let citationIndex: number;
            if (citations.indexOf(part) !== -1) {
                citationIndex = citations.indexOf(part) + 1;
            } else {
                citations.push(part);
                citationIndex = citations.length;
            }

            return renderToStaticMarkup(
                <button className={styles.supContainer} title={part}>
                    <sup>{citationIndex}</sup>
                </button>
            );
        }
    });

    return {
        answerHtml: fragments.join(""),
        citations
    };
}