import { useState } from "react";
import { Stack, TextField } from "@fluentui/react";

import styles from "./QuestionInput.module.css";
import { SendChatButton } from "../SendChatButton";
import { ClearChatButton } from "../ClearChatButton";

interface Props {
    onSend: (question: string) => void;
    sendDisabled: boolean;
    placeholder?: string;
    clearOnSend?: boolean;
    clearChatDisabled: boolean;
    clearChat: () => void;
}

export const QuestionInput = ({ onSend, sendDisabled, placeholder, clearOnSend, clearChatDisabled, clearChat }: Props) => {
    const [question, setQuestion] = useState<string>("");

    const sendQuestion = () => {
        if (sendDisabled || !question.trim()) {
            return;
        }

        onSend(question);

        if (clearOnSend) {
            setQuestion("");
        }
    };

    const onEnterPress = (ev: React.KeyboardEvent<Element>) => {
        if (ev.key === "Enter" && !ev.shiftKey) {
            ev.preventDefault();
            sendQuestion();
        }
    };

    const onQuestionChange = (_ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        if (!newValue) {
            setQuestion("");
        } else if (newValue.length <= 1000) {
            setQuestion(newValue);
        }
    };

    const sendQuestionDisabled = sendDisabled || !question.trim();

    return (
        <Stack horizontal className={styles.questionInputContainer}>            
            <ClearChatButton className={styles.clearChat} onClick={clearChat} disabled={clearChatDisabled} />
            <TextField
                className={styles.questionTextField}
                inputClassName={styles.questionTextFieldInput}
                placeholder={placeholder}
                autoFocus
                resizable={false}
                borderless
                value={question}
                onChange={onQuestionChange}
                onKeyDown={onEnterPress}
            />
            <SendChatButton className={styles.clearChat} onClick={sendQuestion} disabled={sendQuestionDisabled}/>
        </Stack>
    );
};
