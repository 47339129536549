import { useState } from "react";
import styles from "./RatingSystem.module.css";
import { StarFilled, StarRegular } from "@fluentui/react-icons";

export const RatingSystem = (props: { starCount: number, onRating: (rating: number) => void, hidden: boolean })  => {
    const [currentRating, setCurrentRating] = useState(0);
    const [temporaryRating, setTemporaryRating] = useState(0);

    const onHover = (rating: number) => {
        setTemporaryRating(rating);
    };

    const onClick = (rating: number) => {
        setCurrentRating(rating);
        props.onRating(rating);
    };

    const onMouseLeave = () => {
        setTemporaryRating(0);
    };

    return (
        <div className={props.hidden ? styles.hide : styles.flex}>            
            <div className={styles.stars_container}>
            {[...Array(props.starCount).keys()].map(index => {
                return (temporaryRating > 0 && index + 1 <= temporaryRating) || (temporaryRating == 0 && currentRating > 0 && index + 1 <= currentRating) ? (
                    <StarFilled
                        onMouseOver={() => onHover(index + 1)}
                        onMouseLeave={() => onMouseLeave()}
                        onClick={() => onClick(index + 1)}
                        className={styles.rating_star}
                        alt-text={"filled star"}
                        primaryFill="rgba(18, 59, 182, 1)"
                    />
                ) : (
                    <StarRegular
                        onMouseOver={() => onHover(index + 1)}
                        onMouseLeave={() => onMouseLeave()}
                        onClick={() => onClick(index + 1)}
                        className={styles.rating_star}
                        alt-text={"empty star"}
                        primaryFill="rgba(18, 59, 182, 1)"
                    />
                );
            })}
        </div>
        </div>
    );
};
