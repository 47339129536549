import styles from "./Example.module.css";

interface Props {
  text: string;
  onClick: (value: string) => void;
}

export const Example = ({ text, onClick }: Props) => {
  return (
    <button
      className={styles.example}
      onClick={() => onClick(text)}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          onClick(text);
        }
      }}
      aria-label={`Example: ${text}`}
    >
      <p className={styles.exampleText}>{text}</p>
    </button>
  );
};
